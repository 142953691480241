.newsletter-teaser {
  $root: &;
  position: relative;

  &__step-1 {
    color: $greenDarkColor;
    padding: 16px 10px 8px;
    background-position: 75%;
    background-color: $greenLighterColor;
    border-radius: $baseBorderRadius;

    @media (min-width: $screen-tablet-landscape) {
      padding: 24px 24px 16px;
    }

    #{$root}__row {
      @media (min-width: $screen-tablet-sm) {
        max-width: 75%;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-width: 60%;
      }

      &--footer {
        padding: 0;
      }
    }
  }

  &__row {
    padding: 8px 0;

    h2,
    h3 {
      margin: 0 0 15px;
      font-weight: $headlineH2FontWeight;
    }

    p {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: currentColor !important;
    }
  }

  &__headline {
    @include fontSize($fontSizeXXXLarge);
    font-weight: $fontWeightBold;
    line-height: 1.2;
    width: 100%;
  }

  &__text {
    display: block;
    font-size: 18px;
    line-height: 1.1;
  }

  &__form {
    margin-top: 32px;
  }

  &__success {
    background: $greenLighterColor;
    padding: 24px 24px 16px;
  }

  .newsletter-form {
    &__declaration {
      font-size: $fontSizeXSmall !important;
      font-weight: $fontWeightThin;
      margin-top: -8px;
      display: block;
    }
  }

  .radio-input__label {
    color: currentColor;
  }

  &__next-steps {
    @include clearList();
    margin: 0;

    @media (min-width: $screen-tablet-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: start;
      margin: 0 auto 10px;
    }

    .icon::before {
      color: $brandPrimaryColor;
      font-size: 50px;
    }

    .icon--confirmationArrow::before {
      font-size: 12px;
      color: $grayDarkColor;
    }

    img {
      padding: 0 0 10px;
    }
  }

  &__next-step {
    font-size: $fontSizeXSmall;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
    width: 100%;

    @media (min-width: $screen-tablet-sm) {
      font-size: $fontSizeSmall;
      max-width: 200px;
      padding: 0 15px;
      margin-bottom: 15px;
      flex-basis: 30%;
    }

    &:first-child {
      padding-left: 0;
    }

    &--link {
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-sm) {
        transform: none;
      }
    }
  }

  &__icon-text {
    margin: 8px auto 0;
    max-width: 158px;

    @media (min-width: $screen-tablet-sm) {
      max-width: inherit;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
newsletter-teaser {
  .loader {
    top: 56px;
  }

  .newsletter-teaser__success {
    display: none;
  }
}
