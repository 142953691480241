.image-text-action-teaser {
  $root: &;
  width: 100%;
  height: 100%;
  padding: 0;

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
    position: relative;
  }

  &__img {
    img {
      width: 100%;
      display: inherit;
      border-radius: $baseBorderRadius;
    }
  }

  &__box {
    background: $whiteColor;
    padding: 16px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: $imageTextTeaserMinHeight;
    flex-grow: 2;
  }

  &__header {
    @include fontSize($fontSizeXXXMedium);
    color: $textBaseColor;
    margin-bottom: 16px;
    line-height: 115%;
  }

  &__subline {
    color: $textBaseColor;
    padding: 0 0 16px;
    display: inline-block;
    line-height: 1.4;
    flex-grow: 2;
    font-weight: $fontWeightThin;
  }

  &__button {
    margin: 0 0 10px;
    align-self: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: auto;
    line-height: 42px;
    text-transform: uppercase;
  }

  &--default {
    #{$root}__img-wrapper {
      background: $whiteColor;
      padding: 16px;
    }

    #{$root}__box {
      margin-top: -16px;
    }
  }

  &--teaser-wide {
    #{$root}__link {
      width: 100%;
      text-decoration: none !important;

      @media (min-width: $screen-tablet-landscape) {
        flex-direction: row;
        justify-content: center;
      }
    }

    #{$root}__img-wrapper {
      position: relative;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
      }
    }

    #{$root}__img-text {
      position: absolute;
      top: 0;
      width: 100%;
      color: $whiteColor;
      text-align: center;
      display: flex;
      justify-content: center;

      h2,
      h3,
      h4 {
        padding: 16px 30px;
        line-height: 1.1;
        width: 100%;
        margin: 0;
        color: $whiteColor;
      }
    }

    #{$root}__box {
      background: #e7e0c3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      text-align: center;
      min-height: auto;
      border-radius: 0;

      @media (min-width: $screen-tablet-landscape) {
        text-align: left;
        min-width: 40%;
        max-width: 40%;
        width: auto;
        border-radius: 0;
      }

      @media (min-width: $screen-desktop) {
        padding: 32px;
        min-width: 33%;
        max-width: 35%;
      }
    }

    #{$root}__header {
      color: $textBaseColor;
      font-weight: $fontWeightBold;
    }

    #{$root}__subline {
      display: inline-block;
      width: 100%;
      color: $textBaseColor;
      line-height: 1.4;
      padding: 0;
      flex-grow: 0;
      font-size: 16px;

      p {
        margin: 0;
      }
    }

    #{$root}__button-wrapper {
      margin: 20px 0 0;

      @media (min-width: $screen-tablet-landscape) {
        text-align: left;
      }

      @media (min-width: $screen-desktop) {
        margin: 32px 0 0;
      }
    }

    #{$root}__button {
      display: inline-block;
      width: 100%;
      background: var(--button-secondary-background-color);
      color: var(--button-secondary-text-color);
      border-color: var(--button-secondary-border-color);
      max-width: 400px;

      &#{$root}--hover,
      &:hover {
        background: var(--button-secondary-hover-background-color);
        color: var(--button-secondary-text-color);
      }

      &#{$root}--active,
      &:active {
        background: var(--button-secondary-active-background-color);
      }
    }

    picture {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 0;
      object-fit: cover;
    }

    .image-element {
      display: inline;
      padding: 0;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
        padding: 0;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
    }
  }

  &--variant1,
  &--default {
    #{$root}__img-wrapper {
      border-radius: 0;
    }

    #{$root}__box {
      border-radius: 0;
    }
  }

  &--variant1,
  &--parallax {
    height: auto;

    #{$root}__box {
      background: transparent;
      color: $whiteColor;
      position: absolute;
      bottom: 0;
      min-height: auto;
    }

    #{$root}__button {
      width: 90%;
      border: currentColor 1px solid;
      line-height: 42px;
      text-align: center;
      padding: 0 20px;
      margin: 0 auto;
      display: block;
    }
  }

  &--parallax {
    overflow: hidden;
    position: relative;
    max-width: none !important;
    padding: 0 !important;
    margin-bottom: -30px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: -40px;
    }

    #{$root}__bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed; // stylelint-disable-line
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .is_safari & {
        background-attachment: inherit;

        @media (min-width: $screen-tablet-portrait) {
          background-attachment: fixed; // stylelint-disable-line
        }
      }

      &--mobile {
        display: block;

        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @media (min-width: $screen-tablet-portrait) {
          display: block;
        }
      }
    }

    .row.grid__element--bg-color1 &,
    .row.grid__element--bg-color2 &,
    .row.grid__element--bg-color3 &,
    .row.grid__element--bg-color4 &,
    .row.grid__element--bg-color5 & {
      margin-top: -30px;

      @media (min-width: $screen-tablet-portrait) {
        margin-top: -40px;
      }
    }

    #{$root}__box {
      position: relative;
      bottom: auto;
      transform: none;
      text-align: center;
      align-self: center;
      justify-content: center;
      min-height: 300px;
      margin: 20px auto;
      color: $blackColor;

      @media (min-width: $screen-tablet-landscape) {
        margin: 40px auto;
      }

      @media (min-width: $screen-desktop) {
        margin: 80px auto;
        max-width: $maxWidthInner;
      }

      .row.grid__element--bg-color5 & {
        color: $whiteColor;
      }
    }

    #{$root}__button {
      width: auto;
      border-color: $blackColor;
      color: $blackColor;

      &--hover,
      &:hover {
        color: $blackColor;
      }

      .row.grid__element--bg-color5 & {
        border-color: $whiteColor;
        color: $whiteColor;

        &--hover,
        &:hover {
          color: $whiteColor;
        }
      }
    }

    #{$root}__header {
      @include fontSize($fontSizeXXXLarge);
      font-weight: $fontWeightBold;
      color: inherit;
    }

    #{$root}__subline {
      @include fontSize($fontSizeXXMedium);
      font-family: $fontSecondaryFamily;
      line-height: 1;
      padding-bottom: 24px;
      flex-grow: 0;
      color: inherit;
    }
  }

  &--variant2 {
    #{$root}__subline {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 16px 30% 16px 16px;
      background: linear-gradient(0deg, rgb(0 0 0 / .35), #0000); // stylelint-disable-line
      color: $whiteColor;
      min-height: 40%;
      display: flex;
      align-items: end;
      border-radius: $baseBorderRadius;
    }

    picture {
      display: block;
    }

    .image-text-action-teaser__img img {
      display: flex;
    }
  }

  &--preferences {
    overflow: hidden;
    position: relative;

    #{$root}__img-wrapper {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;

      img {
        position: absolute;
        height: 100%;
        display: inline-block;
        width: auto;
        max-width: none;
        margin: auto;
        top: -2000px;
        bottom: -2000px;
        left: -2000px;
        right: -2000px;

        @media (min-width: $screen-desktop) {
          height: auto;
          width: 100%;
        }
      }
    }

    #{$root}__link {
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }

    #{$root}__box {
      position: relative;
      bottom: auto;
      transform: none;
      text-align: center;
      align-self: center;
      color: $blackColor;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(255 255 255 / .6);
      width: auto;
      margin: 15px;
      padding: 15px;

      @media (min-width: $screen-tablet-landscape) {
        margin: 30px;
        padding: 30px;
      }
    }

    .headline--top-line {
      letter-spacing: 1px;
      line-height: 1.2;
      margin: 0 0 6px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 32px;
      }
    }

    .headline--main-line {
      line-height: 1.2;
      font-size: 28px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 32px;
      }

      @media (min-width: $screen-desktop) {
        font-size: 40px;
      }
    }

    .headline--sub-line {
      line-height: 1.2;
      margin: 10px 0 20px;
    }

    #{$root}__button {
      width: auto;
      border-color: $blackColor;
      color: $blackColor;
      align-self: center;
      margin: 0;

      &--hover,
      &:hover {
        color: $blackColor;
      }

      .row.grid__element--bg-color5 & {
        border-color: $whiteColor;
        color: $whiteColor;

        &--hover,
        &:hover {
          color: $whiteColor;
        }
      }
    }
  }

  .grid__element--teaser & {
    #{$root}__header,
    #{$root}__subline {
      color: var(--grid-element-teaser-text-color);

      a {
        color: var(--grid-element-teaser-text-color);
      }
    }

    #{$root}__button {
      color: var(--grid-element-teaser-text-color);
      border-color: var(--grid-element-teaser-text-color);

      &--hover,
      &:hover {
        color: var(--grid-element-teaser-text-color);
      }
    }

    #{$root}__img-wrapper,
    #{$root}__box {
      background: var(--grid-element-teaser-background);
    }

    &#{$root}--default {
      background: var(--grid-element-teaser-background);

      #{$root}__img-wrapper,
      #{$root}__box {
        background: transparent;
      }
    }
  }

  .teasergroup_teaser__coverflow & {
    &#{$root}--default #{$root}__img-wrapper {
      padding: 0;
    }
  }

  // PLACEHOLDER
  .lazy-load-image__image--not-loaded,
  lazy-load-image {  // stylelint-disable-line
    min-height: 200px;
    display: block;
  }
}
